<template>
  <!--
    Beware, when tooltip is rendered, passing `style` attribute will apply to
    both to the tooltip invisible span and the button. Avoid this.
    Why: Vue won't let bind `class` and `style` to children, although we hacked
    that for classes below.
  -->
  <v-btn
    ref="button"
    class="deck-button"
    :value="modelValue"
    :class="classes"
    :style="isReady ? cssProps : undefined"
    :color="isReady ? colorBackground : color"
    :loading="loading"
    :disabled="disabled"
    :href="href"
    :to="to"
    :size="computedSize"
    :variant="variant"
    elevation="0"
    :aria-label="ariaLabel || (icon ? text : null)"
    v-bind="$attrs"
  >
    <deck-icon
      v-if="icon"
      :name="icon"
      :kind="computedIconKind"
      :size="iconSize"
      fixed-width
    />

    <template
      v-if="!icon && iconPrepend"
      #prepend
    >
      <deck-icon
        v-if="iconPrepend"
        :name="iconPrepend"
        :kind="computedIconKind"
        :size="iconSize"
        class="deck-button__icon-prepend"
        fixed-width
      />
    </template>

    <!-- @slot Default slot to render any content into the button -->
    <slot v-if="!icon">
      {{ text }}
    </slot>

    <template
      v-if="!icon && iconAppend"
      #append
    >
      <deck-icon
        v-if="iconAppend"
        :name="iconAppend"
        :kind="computedIconKind"
        :size="iconSize"
        class="deck-button__icon-append"
        fixed-width
      />
    </template>

    <deck-tooltip
      v-if="shouldRenderTooltip"
      ref="tooltip"
      v-bind="computedTooltipProps"
      class="deck-button__tooltip"
    >
      <template
        v-if="$slots.tooltip"
        #content
      >
        <slot name="tooltip" />
      </template>
    </deck-tooltip>
  </v-btn>
</template>

<script lang="ts">
import { getAccessibleColor } from '../utils/color';

/**
 * ### ⚠️ Use this instead of `v-btn` for all buttons on Zazos. Props can be inherited.
 * #### ℹ️ New style will be ready to switch when `v-btn` is no longer directly used.
 *
 * A button component that is styled to adhere to the Deck visual guidelines.
 * **It is always rectangular with smooth rounded corners**.
 *
 * **Warning!** As of now you must use an accessible color against a white
 * background (think of darker colors) and let it do its work. Any variant will
 * try to adapt gracefully.
 *
 * Extends and adapts the Vuetify `v-btn` component.
 * @see https://v2.vuetifyjs.com/en/api/v-btn/
 */
export default defineComponent({
  name: 'DeckButton',
  inheritAttrs: false,
  props: {
    /**
     * The v-model bound modelValue.
     * @type {boolean | number | string}
     */
    modelValue: {
      type: [Boolean, Number, String],
      default: undefined,
    },

    /**
     * The text to be displayed on the button. The default slot can be used as
     * an alternative. Will not be rendered if `icon` is set, but will fill the
     * `aria-label` of the button.
     * @type {string}
     * @default undefined
     */
    text: {
      type: String,
      default: undefined,
    },

    /**
     * The aria-label for the button. Won't be populated if `text` is visibily
     * rendered, as it would be redundant. Will be populated with the `text`
     * value if `icon` is set. Will force populated value if explicitly set.
     * @type {string}
     * @default undefined
     */
    ariaLabel: {
      type: String,
      default: undefined,
    },

    /**
     * Is `true` for stories, and `false` for production until we swap each instance of `v-btn` for `deck-button`.
     * **TODO:** This prop should be removed altogether when we're ready to fully switch.
     * @type {boolean}
     * @default false
     */
    isReady: {
      type: Boolean,
      default: false,
    },

    /**
     * The kind of the button to be used based on its context. `inline` size will behave as expected when `size` is not set.
     * @type {'primary' | 'secondary' | 'ghost' | 'inline' | string}
     * @default 'primary'
     */
    kind: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary', 'ghost', 'inline'].includes(value),
    },

    /**
     * The color of the button. Can also be a valid color string.
     * @type {'primary' | 'controls' | 'success' | 'destructive' | 'brand' | string}
     * @default 'primary'
     */
    color: {
      type: String,
      default: 'primary',
    },

    /**
     * The name of the icon from FontAwesome to exclusively display on the button. No need for `fa-` prefix.
     * This will force the `text` into an `aria-label`.
     * Use `iconPrepend` and `iconAppend` instead if you want to display text alongside the icon.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    icon: {
      type: String,
      default: undefined,
    },

    /**
     * The name of the icon from FontAwesome to display on the left side of the button text. No need for `fa-` prefix.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    iconPrepend: {
      type: String,
      default: undefined,
    },

    /**
     * The name of the icon from FontAwesome to display on the right side of the button text. No need for `fa-` prefix.
     * @type {string}
     * @default undefined
     * @example 'pencil'
     * @see https://fontawesome.com/search
     */
    iconAppend: {
      type: String,
      default: undefined,
    },

    /**
     * The style of FontAwesome icon to use. No need for `fa-` prefix.
     * Will override to 'solid' when `kind` is not `"primary"` or `size` is not `"small"`.
     * Will use explicitly set value otherwise.
     * @type {'regular' | 'solid' | string}
     * @default 'regular'
     */
    iconKind: {
      type: String,
      default: undefined, // undefined will default to 'regular' in the computed property unless explicitly set
    },

    /**
     * A static URL to navigate to when the button is clicked. Use `to` instead if you want to navigate to a route.
     * @type {string}
     * @default undefined
     * @see https://v2.vuetifyjs.com/en/api/v-btn/#props-href
     */
    href: {
      type: String,
      default: undefined,
    },

    /**
     * The route object to navigate to when the button is clicked. Use `href` instead if you want to navigate to a static URL.
     * @type {Object}
     * @default undefined
     * @see https://v2.vuetifyjs.com/en/api/v-btn/#props-to
     */
    to: {
      type: [Object, String],
      default: undefined,
    },

    /**
     * Variant sizes of the button. Is `default` for any kind if not set, except for `kind="inline"` where its size is controled by its parent font-size, unless explicitly set otherwise.
     * @type {'default' | 'small' | 'large' | string}
     * @default 'default'
     */
    size: {
      type: String,
      default: undefined, // undefined will default to 'default' in the computed property
    },

    /**
     * Whether the button should be rendered stretched as a full-width block element.
     * @type {boolean}
     * @default false
     */
    block: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether to display a loading spinner on the button. This remove icons and text and also disables the button.
     * @type {boolean}
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether the button has disabled interactions and styles.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Applies toggled state style to the button.
     * @type {boolean}
     * @default false
     */
    toggled: {
      type: Boolean,
      default: false,
    },

    /**
     * Additional props to be passed to the `deck-tooltip` component if `icon` and `text` are both set.
     * @type {Object}
     * @default {}
     */
    tooltipProps: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Justify the text inside the button.
     * @type {string}
     * @default undefined
     */
    justifyText: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      zazos: useZazos(),
    };
  },
  data() {
    return {
      buttonRef: null,
    };
  },
  computed: {
    cssProps() {
      return {
        '--deck-button-color-background': this.colorBackground,
        '--deck-button-color-text': this.colorText,
        '--deck-button-color-primary': this.availableColors.primary,
      };
    },
    classes() {
      return {
        'deck-button--is-ready': this.isReady, // TODO: Remove when ready to switch v-btn to deck-button widely

        'deck-button--is-primary': this.isReady && this.kind === 'primary',
        'deck-button--is-secondary': this.isReady && this.kind === 'secondary',
        'deck-button--is-ghost': this.isReady && this.kind === 'ghost',
        'deck-button--is-inline': this.isReady && this.kind === 'inline',
        'deck-button--is-icon': !!this.icon,
        'deck-button--is-default': this.computedSize === 'default',
        'deck-button--is-small': this.computedSize === 'small',
        'deck-button--is-large': this.computedSize === 'large',
        'deck-button--is-block': this.block,
        'deck-button--is-disabled': this.isReady && this.disabled,
        'deck-button--is-toggled': this.isReady && this.toggled,
        'deck-button--is-controls': this.isReady && this.color === 'controls',
        [`deck-button--justify-${this.justifyText}`]: this.justifyText,
        'v-btn--active': !this.isReady && this.toggled, // TODO: Remove when ready to switch v-btn to deck-button widely
      };
    },
    availableColors() {
      return {
        primary: this.$vuetify.theme.current.colors.primary,
        controls: 'var(--z-color-controls)',
        success: this.$vuetify.theme.current.colors.success,
        destructive: this.$vuetify.theme.current.colors.error,
        brand: '#FD8C41', // Zazos Color // TODO: Replace with token when available
      };
    },
    colorBackground() {
      if (this.kind === 'secondary') return 'transparent';
      if (this.kind === 'ghost') return 'transparent';
      if (this.kind === 'inline') return 'transparent';

      return this.availableColors[this.color] || this.color;
    },
    colorText() {
      /** TODO: Improve color accessibility of `secondary`, `ghost` and `inline`
       * kinds by using a color contrast algorithm to set the `colorText` to the
       * nearest accessible darker shade of the defined color. Should favor
       * using the new APCA algorithm.
       */
      if (this.kind === 'primary') return getAccessibleColor(this.colorBackground, this.zazos.darkMode.value);

      return this.availableColors[this.color] || this.color;
    },
    computedSize() {
      if (this.size === undefined && this.kind === 'inline') return undefined; // Will be controlled by parent font-size
      if (this.size === undefined) return 'default'; // Sensible Default
      return this.size;
    },
    iconSize() {
      if (this.size === undefined && this.kind === 'inline') return 'font'; // Use size of font from deck-icon
      return this.size;
    },
    computedIconKind() {
      if (this.iconKind === undefined) return undefined; // Fallback to deck-icon decision
      if (this.iconKind !== undefined) return this.iconKind; // Explicitly set override
      return 'regular'; // Sensible Default
    },
    shouldRenderTooltip() {
      return (
        (this.icon !== undefined && this.text !== undefined) || (this.tooltipProps.text !== undefined || this.$slots.tooltip)
      );
    },
    computedTooltipProps() {
      return {
        ...this.tooltipProps,
        text: this.tooltipProps.text || this.text, // Auto populate tooltip with `text` prop when button is icon only
        activator: this.buttonRef,
      };
    },
    variant() {
      // TODO: Check this default with @caio
      if (this.isReady) return 'flat';
      if (this.kind === 'secondary') return 'outlined';
      if (this.kind === 'ghost') return 'text';

      return 'flat';
    },
  },
  watch: {
    modelValue() {
      this.$emit('update:modelValue', this.modelValue);
    },
  },
  mounted() {
    nextTick().then(() => {
      this.buttonRef = this.$refs?.button?.$el;
    });
  },
});
</script>
<style lang="scss">

// Root
.deck-button:is(.deck-button--is-ready) { // TODO: Remove when ready to switch v-btn to deck-button widely
  --deck-button-fade-transition: 100ms ease;
  --deck-button-background-transition: background-color var(--deck-button-fade-transition);
  --deck-button-overlay-transition: opacity var(--deck-button-fade-transition);

  align-items: center;
  background-color: var(--deck-button-color-background) !important;
  border-radius: var(--z-border-radius-inner-base);
  border: none !important;
  color: var(--deck-button-color-text) !important;
  display: flex;
  font-size: var(--deck-button-font-size);
  font-weight: var(--deck-button-font-weight);
  height: var(--deck-button-height) !important;
  letter-spacing: 0.4px;
  padding-inline: var(--deck-button-padding-inline) !important;
  text-indent: 0 !important; // Fuck you vuetify srsly
  transition: var(--deck-button-background-transition);

  .v-btn__overlay {
    color: var(--deck-button-overlay-color) !important;
    opacity: var(--deck-button-overlay-opacity) !important;
    transition: var(--deck-button-overlay-transition) !important;
    border-radius: inherit;
    z-index: -1; // prevent painting icons
  }

  &:is(:hover, :focus-visible) .v-btn__overlay {
    opacity: var(--deck-button-overlay-opacity-hover) !important;
    color: var(--deck-button-overlay-color-hover, var(--deck-button-overlay-color)) !important;
  }
  &:not(.deck-button--is-disabled):hover {
    z-index: 7; // right below v-menus
  }

  &:not(.deck-button--is-disabled):hover {
    z-index: 7; // right below v-menus
  }

  .v-progress-circular {
    height: 50% !important; // Ensures loading spinner has proportional size
  }

  .v-ripple__animation {
    background-color: var(--deck-button-overlay-color) !important;
  }

  .v-btn__content {
    flex: 1;
    min-width: 0;
  }
}

.deck-button--is-default {
  // dimensions
  --deck-button-height: 36px;
  // spacing
  --deck-button-padding-inline: 16px;
  // font
  --deck-button-font-size: 14px;
}

.deck-button--is-small {
  // dimensions
  --deck-button-height: 28px;
  // spacing
  --deck-button-padding-inline: 8px;
  // font
  --deck-button-font-size: 12px;
  --deck-button-font-weight: 600;
}

.deck-button--is-large {
  // dimensions
  --deck-button-height: 44px;
  // spacing
  --deck-button-padding-inline: 20px;
  // font
  --deck-button-font-size: 16px;
}

.deck-button--is-primary {
  // color
  --deck-button-color-background-hover: var(--deck-button-color-background);
  // overlay
  --deck-button-overlay-color: white;
  --deck-button-overlay-opacity: 0;
  --deck-button-overlay-opacity-hover: 0.2;
  // font
  --deck-button-font-weight: 500;
}

.deck-button--is-secondary {
  // overlay
  --deck-button-overlay-color: var(--deck-button-color-text);
  --deck-button-overlay-opacity: 0.15;
  --deck-button-overlay-opacity-hover: 0.6;
  // font
  --deck-button-font-weight: 600;
}

.deck-button--is-ghost {
  // overlay
  --deck-button-overlay-color: var(--deck-button-color-text);
  --deck-button-overlay-opacity: 0;
  --deck-button-overlay-opacity-hover: 0.25;
  // font
  --deck-button-font-weight: 600;
}

.deck-button--is-inline {
  // scoped
  --_simulated-padding-inline: 4px;
  --_simulated-padding-block: 2px;
  // overlay
  --deck-button-overlay-color: var(--deck-button-color-text);
  --deck-button-overlay-opacity: 0;
  --deck-button-overlay-opacity-hover: 0.25;
  // font
  --deck-button-font-weight: 600;
  // dimensions
  --deck-button-height: unset !important;
  // spacing
  --deck-button-padding-inline: 0;

  display: inline-flex;
  border-radius: var(--z-border-radius-inner-base);
  width: unset !important;
  min-width: unset !important;
  font-size: var(--deck-button-font-size, 1em) !important; // override .deck-button--is-ready // TODO: remove !important when ready to switch v-btn to deck-button widely
  line-height: inherit !important;
  vertical-align: baseline !important;

  .v-btn__content {
    align-items: baseline !important;
  }

  // Simulate an unobtrusive small padding on the overlay for breathing room when hovering without layout shifting
  &.v-btn__underlay {
    pointer-events: unset !important; // This allows a consistent larger click area aligned with the hover overlay
  }

  &:not(.deck-button--is-icon) {
    &.v-btn__underlay, .v-ripple__container {
      width: unset;
      height: unset;
      top: calc(-1 * var(--_simulated-padding-block));
      bottom: calc(-1 * var(--_simulated-padding-block));
      left: calc(-1 * var(--_simulated-padding-inline));
      right: calc(-1 * var(--_simulated-padding-inline));
    }

    .v-ripple__animation {
      box-sizing: content-box;
      padding-inline: var(--_simulated-padding-inline);
      padding-block: var(--_simulated-padding-block);
    }
  }

  &.deck-button--is-icon {
    --_min-touch-area: 32px;
    --_touch-area: max(var(--_min-touch-area), 2em); // scale with font size but keep a minimum of 32px
    --_touch-area-center-position: calc(50% - var(--_touch-area) / 2);

    &.v-btn__underlay, .v-ripple__container {
      width: var(--_touch-area);
      height: var(--_touch-area);
      top: var(--_touch-area-center-position);
      left: var(--_touch-area-center-position);
    }

    .v-ripple__animation {
      box-sizing: content-box;
      padding: calc(max(var(--_min-touch-area) - 1em, 1em) / 2);
    }
  }
}

.deck-button--is-icon {
  padding: 0 !important;
  &:not(.deck-button--is-inline) { // Avoid overriding inline button dimensions reset
    // Force a 1:1 aspect-ratio button
    width: var(--deck-button-height) !important;
    min-width: var(--deck-button-height) !important;
  }
}

.deck-button--is-controls {
    // overlay
    --deck-button-overlay-color: var(--deck-button-color-primary);

  &.deck-button--is-secondary {
    // overlay
    --deck-button-overlay-color: var(--deck-button-color-text);
    --deck-button-overlay-color-hover: var(--deck-button-color-primary) !important;
  }

}

.deck-button--is-block {
  width: stretch;
}

.deck-button--is-disabled {
  &.v-btn.v-btn--disabled { // necessary due to specificity
    // overlay
    --deck-button-overlay-color: black;
    --deck-button-overlay-color-hover: black;
    --deck-button-overlay-opacity: 0.25;
    --deck-button-overlay-opacity-hover: 0.25;

    color: var(--z-input-placeholder-disabled-color) !important; // override .v-btn--disabled

    &.deck-button--is-primary, &.deck-button--is-secondary {
      background-color: white !important; // override .v-btn--disabled
    }

    &.deck-button--is-ghost, &.deck-button--is-inline {
      background-color: var(--deck-button-color-background) !important; // override .v-btn--disabled
    }

    &.deck-button--is-ghost, &.deck-button--is-inline {
      // overlay
      --deck-button-overlay-opacity: 0 !important;
      --deck-button-overlay-opacity-hover: 0 !important;
    }

    &.deck-button--is-secondary {
      // overlay
      --deck-button-overlay-opacity: 0.12 !important;
      --deck-button-overlay-opacity-hover: 0.12 !important;
    }
  }
}

.deck-button--is-toggled {
  --deck-button-overlay-opacity: 0.6;

  .v-btn__content {
    color: var(--deck-button-color-text) !important;
  }
}

.deck-button--justify-start {
  .v-btn__content {
    justify-content: flex-start;
  }
}
</style>
